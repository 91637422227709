a,
a:focus,
a:hover {
  color: #fff;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none;
  background-color: #fff;
  border: 0.05rem solid #fff;
}

/*
 * Base structure
 */

html,
body {
  height: 100%;
  background-color: #000000;
}

body {
  color: #fff;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}

/*
 * Footer
 */
.mastfoot {
  color: rgba(255, 255, 255, 0.5);
}

.home-page-main-note {
  position: relative;
  margin-left: 0;
  margin-right: auto;
  width: 8em;
}

/*Header */

.brand {
  left: 30%;
  margin-left: 35%;
  background-color: rgba(0, 0, 0, 0.2);
}

.main-header {
  position: absolute;
  width: 100%;
}

.active-header-link {
  color: #fff;
  border-bottom-color: #fff;
}

.header-link {
  font-weight: 700;
  color: rgba(255, 215, 0, 0.5);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
  margin-right: 20px;
  font-size: 100%;
}

.main-navbar {
  background-color: rgba(0, 0, 0, 0.1);
  height: 65px;
}

.page-margin-with-header {
  margin-top: 70px;
}

.header-navbar-collapse {
  background-color: rgba(0, 0, 0, 0.5);
}

.header-navbar-toggle {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
/*Age confirmation*/

.age-confirmation-modal .modal-content {
  background-color: #1a1a1a;
  color: white;
  border-color: white;
}

.age-confirmation-btn {
  outline-color: #fff;
  background-color: #313131;
  border-color: white;
  color: white;
}

.btn-large-modal-btn {
  font-size: 1.5rem;
}

@media (max-width: 900px) {
  .header-logo {
    width: 57px;
    height: 75px;
  }

  .carousel img {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .home-page-item {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
  }

  .home-page-item-1 {
    text-align: center;
    min-height: 300px;
    position: relative;
    margin-top: 10px;
  }

  .home-page-item-2 {
    text-align: center;
    min-height: 300px;
    position: relative;
    margin-top: 10px;
  }

  .home-page-item-3 {
    margin-top: 10px;
  }

  .about-us-side-img {
    max-height: 350px;
    max-width: 250px;
  }

  .hide-me-if-small {
    font-size: 0;
  }

  .youtube-player {
    width: 100%;
    height: 100%;
  }

  .medal-detail {
    margin-left: 83%;
    inline-size: 100px;
    overflow-wrap: break-word;
    text-align: right;
  }
}

@media (min-width: 901px) {
  .header-logo {
    width: 76px;
    height: 100px;
  }

  .carousel img {
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .home-page-item {
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
  }

  .home-page-item-1 {
    text-align: center;
    min-height: 700px;
    position: relative;
    margin-top: 10px;
  }

  .home-page-item-2 {
    text-align: center;
    min-height: 700px;
    position: relative;
    margin-top: 10px;
  }

  .home-page-item-3 {
    margin-top: 10px;
  }

  .contact-us-modal {
    width: 50vw;
    max-width: 50vw;
  }

  .about-us-side-img {
    max-height: 500px;
    width: auto;
    max-width: 400px;
    height: auto;
    margin: auto;
    overflow: hidden;
  }

  .youtube-player {
    width: 50%;
    height: 50%;
  }

  .medal-detail {
    margin-left: 90%;
    inline-size: 100px;
    overflow-wrap: break-word;
    text-align: right;
  }
}

.masterfooter {
  left: 0;
  bottom: 0;
  right: 0;
}

.app-main {
  position: relative;
  padding-bottom: 60px;
}

.space-creator {
  visibility: hidden;
}

.comming-soon-page {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 400px;
}

.access-denied-page {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 400px;
}

.main-body {
  padding-top: 10px;
}

.contact-us .modal-content {
  color: rgb(255, 255, 255);
  border-color: #fff;
  background-color: rgba(0, 0, 0, 0.801);
}

.contact-us-address-area {
  margin-left: 40px;
  font-size: 20px;
}

.about-page {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
  position: relative;
}

.space-between-comps {
  height: 10px;
  width: 100%;
  clear: both;
}

.recipes-list ul.a {
  list-style-type: circle;
}

.recipes-list {
  color: rgba(0, 0, 0, 0.5);
}

.cocktail-recipe-title {
  color: rgba(0, 0, 0, 0.5);
}

.available-areas-list {
  /* margin-top: 15px; */
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  border-color: #fff;
}

.store-accordion-title {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  z-index: 2000;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  text-transform: uppercase;
  font-weight: bold;
}

.store-accordion-body {
  background-color: rgba(0, 0, 0, 0.8);
}

.image.slide {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 100vh;
}

.carousel-caption {
  top: 0;
  bottom: auto;
  text-align: right;
  padding-top: 100px;
}

.youtube-player {
  margin: auto;
}
